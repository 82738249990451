import JSEncrypt from "jsencrypt";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { loginAPI } from "../service/AuthService";
import { PUBLIC_KEY } from "../utils/constants";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { generateHash } from "../service";
import { Center } from "@chakra-ui/react";

function LoginPage() {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setIsLoading] = useState(false);
  const login = async (e) => {
    setIsLoading(true);
    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(password);
    let fp = await generateHash();
    localStorage.setItem("fingerprint", fp);
    var { data, code, message, response, error } = await loginAPI(
      userName.replace("-", ""),
      encryptedPassword
    );
    if (code === 200) {
      setIsLoading(false);
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: false,
      });
      dispatch({
        type: "accountData/setChangePopSate",
        payload: response.resetRequired,
      });
    } else if (code == 500) {
      toast.error(response);
      setIsLoading(false);
    } else if (code == 403) {
      setIsLoading(false);
      toast.error(error.description);
    } else if (code == 401) {
      setIsLoading(false);
      toast.error(response);
    } else {
      toast.error("Something went wrong or Account is not active");
    }
  };
  return (
    <>
      {loading ? (
        <>
          <div className="position-fixed w-100 h-100 d-flex align-items-center justify-content-center loader">
            <img src="/logo.png" alt="Loader" class="flip-vertical-right" />
            {/* Loading... */}
          </div>
        </>
      ) : null}
      <div
        className="sc-dWcDbm iADzKJ snipcss-tR7m1 style-UzD4O"
        id="style-UzD4O"
        style={{
          backgroundImage:
            "url('https://opt-v3-files.raksahb.com/static/mobile/brand/31/image/auth-bg-newAug.jpg')", // Add background image
          backgroundSize: "cover", // Ensure the image covers the entire div
          backgroundPosition: "center", // Center the image within the div
        }}
      >
        <div className="sc-fdqjUm jrMOYv">
          <div className="sc-cClmTo sc-csZoYU hxBqlt">
            <img
              style={{
                height: "38px",
              }}
              src="/logo.png"
            />
          </div>
        </div>
        <div
          className="sc-idjmjb sc-ihiiSJ gPOtIJ"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-100px",
          }}
        >
          <div className="sc-fHlXLc bBwrS">
            <h4 className="sc-iKpIOp dKuVpg"> Login</h4>
          </div>
          <div className="sc-AqAhp sc-eAyhxF fnNlPO">
            <label className="sc-kAKrxA lisheX">
              <span className="sc-lhLRcH dyrUWh">
                LOGIN ID<span style={{ marginLeft: "10px" }}>*</span>
              </span>
              <input
                type="text"
                id="userName"
                value={userName}
                onChange={(e) => setUsername(e.target.value)}
                name="userName"
                defaultValue=""
                style={{ color: "black" }}
                placeholder="Login ID"
              />
            </label>
            <h1 />
            <div className="sc-cZLAbK jnVwYT">
              <label className="sc-kAKrxA lisheX">
                <span className="sc-lhLRcH dyrUWh">
                  Password<span style={{ marginLeft: "10px" }}>*</span>
                </span>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  style={{ color: "black" }}
                  defaultValue=""
                />
              </label>
              <div className="sc-iVOTot iPXXpm">
                <svg
                  width={22}
                  height={21}
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6061 9.9709C17.662 5.87549 14.7232 3.81445 10.7837 3.81445C6.84209 3.81445 3.90537 5.87549 1.96123 9.97295C1.88325 10.1381 1.8428 10.3184 1.8428 10.501C1.8428 10.6836 1.88325 10.864 1.96123 11.0291C3.90537 15.1245 6.84414 17.1855 10.7837 17.1855C14.7253 17.1855 17.662 15.1245 19.6061 11.0271C19.7641 10.6948 19.7641 10.3093 19.6061 9.9709V9.9709ZM10.7837 15.709C7.47578 15.709 5.05381 14.0314 3.3455 10.5C5.05381 6.96855 7.47578 5.29102 10.7837 5.29102C14.0916 5.29102 16.5136 6.96855 18.2219 10.5C16.5156 14.0314 14.0936 15.709 10.7837 15.709ZM10.7017 6.89062C8.7083 6.89062 7.09228 8.50664 7.09228 10.5C7.09228 12.4934 8.7083 14.1094 10.7017 14.1094C12.695 14.1094 14.311 12.4934 14.311 10.5C14.311 8.50664 12.695 6.89062 10.7017 6.89062ZM10.7017 12.7969C9.43222 12.7969 8.40478 11.7694 8.40478 10.5C8.40478 9.23057 9.43222 8.20312 10.7017 8.20312C11.9711 8.20312 12.9985 9.23057 12.9985 10.5C12.9985 11.7694 11.9711 12.7969 10.7017 12.7969Z"
                    fill="black"
                  />
                </svg>
              </div>
              <h1 />
            </div>
            <div className="sc-dCaJBF fvDnWc" />
            <div className="sc-eVrGFk hfHJim">
              <div className="sc-dCaJBF fvDnWc">
                <div className="sc-dpiBDp chXqJk">
                  <label className="sc-hENMEE dRKoPa">
                    <input
                      type="checkbox"
                      name="default"
                      required={true}
                      spellCheck="false"
                      className="sc-ccbnFN fdyjly"
                      defaultValue=""
                    />
                    <div className="agreeAge">Remember me</div>
                  </label>
                </div>
                <div className="sc-dpiBDp chXqJk">
                  <label className="sc-hENMEE dRKoPa">
                    <input
                      type="checkbox"
                      name="ageValid"
                      spellCheck="false"
                      className="sc-ccbnFN fdyjly"
                      defaultValue="true"
                      required={true}
                      defaultChecked=""
                    />
                    <div className="agreeAge">
                      I am over 18, I agree to the{" "}
                      <span className="agreeAgeColor">
                        Terms and Condition{" "}
                      </span>
                      &amp;
                      <span className="agreeAgeColor"> Privacy Policy</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="sc-fxgLge fltqBV">
              <button
                type="submit"
                className="sc-gtXRHa QeAZN"
                disabled={
                  userName.length > 0 && password.length > 0 ? false : true
                }
                onClick={login}
                style={{
                  backgroundColor:
                    userName.length > 0 && password.length > 0
                      ? "#22b8bd"
                      : "#d3d3d3", // Change color based on input
                  color:
                    userName.length > 0 && password.length > 0
                      ? "white"
                      : "#888", // Text color for contrast
                  cursor:
                    userName.length > 0 && password.length > 0
                      ? "pointer"
                      : "not-allowed", // Cursor change for disabled/enabled state
                }}
              >
                Login
              </button>
            </div>
            <div className="sc-cLmFfZ jfklLT">
              <div className="sc-eNPDpu sc-iNovjJ egDadO">
                <img
                  src="https://opt-v3-files.raksahb.com/static/media/sponsersYolonew.png"
                  alt="yolo award"
                  className="award-icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LoginPage;
